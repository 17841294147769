import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown, Link, Badge, Modal ,Button} from '@/arco'
import { changeLanguage } from '@/api/common'
// import menu from './menu'
import MenuList from '@/components/MenuList'
import styles from './index.module.less'
import HeaderLogo from '@/assets/headerLogo.svg'
import HeaderLogos from '@/assets/headerLogos.svg'
import Zh from '@/assets/common/zh.svg'
import ZhS from '@/assets/common/zhS.svg'
import En from '@/assets/common/en.svg'
import EnS from '@/assets/common/enS.svg'
import MessageIcon from '@/assets/MessageIcon.svg'
// import Portrait from '@/assets/portrait.png'
import Default from '@/assets/default.svg'
// import languageAction from '@/redux/actions/languageType'
// import loading from '@/redux/actions/loading'
import UserInfo from '@/components/UserInfo'
import MessageCenter from '@/components/MessageCenter'
import UserType from '@/assets/center/userType.svg'
import CuserEn from '@/assets/center/cUser_en.svg'
import ApiLogo from '@/assets/api/apiLogo.svg'
import Down from '@/assets/api/down.svg'
import HomeInputSearch from '@/components/HomeInputSearch'
import cs from 'classnames'
import language from '@/utils/language'
import eventStation from '@/utils/eventStation'
export default function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector(state => state.userInfo)
  // const [] = useState()
  const isSpecialHeader = location.pathname == '/knowledge/treat-Img'
  //获取语言类型
  const languages = useSelector(state => state.language)
  //未读消息数
  const messageCount = useSelector(state => state.messageCount)
  //获取显示区域的宽度
  const contentWidth = useSelector(state => state.contentWidth)
  //是否显示全局搜索框
  const showGlobalInput = useSelector(state => state.showGlobalInput)
  //导航栏数据
  const menuList = useSelector(state => state.menuList)
  const [showMenuData, setShowMenuData] = useState([])
  const [isShowShadow, setIsShowShadow] = useState(false)
  const [isApi, setIsApi] = useState(false)
  const [positionLeft, setPositionLeft] = useState(0)
  useEffect(() => {
    if (menuList.length) {
      const newMenu = menuList.map((item) => {
        //判断是否在api中心
      
        if(location.pathname.indexOf('/api')>-1){
           setIsApi(true)
        }else{
          setIsApi(false)
        }
        //API做完需要注释
        if (item.type == 'api') {
          item.menu = [{
            type: 1,
            children: language('header14'),
            // to: `/rq/doc${languages == 'en' ? '/en' : ''}/TianJiPartnersAPIUserManual.pdf`,
            // target:"_blank"
            callback: () => {
              if (userInfo.loginStatus == 0 && !userInfo.loading) {
                navigate('/login', {
                  state: {
                    path: location.pathname
                  }
                })
              } else {
                window.open(`/rq/doc${languages == 'en' ? '/en' : ''}/TianJiPartnersAPIUserManual.pdf`) 

              }
            }
          }, {
            type: 2,
            children: language('home18'),
            // to: `/rq/doc${languages == 'en' ? '/en' : ''}/client.rar`,
            // target:"_blank"
            callback: () => {
              if (userInfo.loginStatus == 0 && !userInfo.loading) {
                eventStation.navigate('/login', {
                  state: {
                    path: location.pathname
                  }
                })
              } else {
                window.open(`/rq/doc${languages == 'en' ? '/en' : ''}/client.rar`)
              }
            }
          }]
        }  
         // API模块做完可以放开
        if (item.type == 'api') {
          item.menu = [{
            type: 'mock-request',
            children: language('apiCenter4'),
            // to: `/rq/doc${languages == 'en' ? '/en' : ''}/TianJiPartnersAPIUserManual.pdf`,
            // target:"_blank"
            callback: () => {
              if (userInfo.loginStatus == 0 && !userInfo.loading) {
                navigate('/login', {
                  state: {
                    path: location.pathname
                  }
                })
              } else {
                window.open(`/api/mock-request`)
              }
            }
          }, {
            type:'call',
            children: language('apiCenter5'),
            // to: `/rq/doc${languages == 'en' ? '/en' : ''}/client.rar`,
            // target:"_blank"
            callback: () => {
              if (userInfo.loginStatus == 0 && !userInfo.loading) {
                eventStation.navigate('/login', {
                  state: {
                    path: location.pathname
                  }
                })
              } else {
                window.open(`/api/call`)
              }
            }
          },
          {
            type:'question',
            children: language('apiCenter6'),
            // to: `/rq/doc${languages == 'en' ? '/en' : ''}/client.rar`,
            // target:"_blank"
            callback: () => {
              if (userInfo.loginStatus == 0 && !userInfo.loading) {
                eventStation.navigate('/login', {
                  state: {
                    path: location.pathname
                  }
                })
              } else {
                window.open(`/api/question`)
              }
            }
          }
        ]
        } 
       
        return item;
      })
      setShowMenuData(newMenu)

    }
   
  }, [menuList, userInfo,location.pathname])

  const dispatch = useDispatch();
  const querUserInfo = useSelector(state => state.userInfo)
  

  function handleScrollTopBoxShadow() {
    const _top = window.scrollY;
    const _left = window.scrollX;
    setPositionLeft(_left * -1);
    setIsShowShadow(_top > 0)
  }
  function handleScrollLeft() {
    const _left = window.scrollX;
    setPositionLeft(_left * -1);
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScrollTopBoxShadow)
    window.addEventListener('resize', handleScrollLeft)
    return () => {
        window.removeEventListener('scroll', handleScrollTopBoxShadow)
        window.removeEventListener('resize', handleScrollLeft)
    }
  }, [])
  //中英文切换方法
  async function changeLanguageType() {
    const lang = languages == "en" ? "zh" : "en";
    const result = await changeLanguage({ lang })
    window.location.reload()
    // dispatch(languageAction(lang))
    // dispatch(loading(true))
    // setTimeout(() => {
    //   dispatch(loading(false))
    // }, 3000);
  }
  // const [menuList, setMenuList] = useState([])
  // useEffect(() => {
  //   setMenuList(menu(language))
  // }, [language])
  // function toUserCenter() {
  //   navigate('/center')
  // }
  //是否显示用户信息
  function isShowUserInfo() {
    if (querUserInfo.loginStatus == 1 && querUserInfo.user) {
      return <>
        <li>
          <Badge count={messageCount} maxCount={99} style={{ cursor: "pointer" }} children={<MessageIcon className={cs({ [styles.specialNotice]: isSpecialHeader })} />} onClick={showMessageCenter} />
        </li>
        <Dropdown trigger="hover" onClick={() => {
          navigate('/center')
        }} droplist={<UserInfo />} getPopupContainer={() => {
          return document.getElementById('PopupContainer')
        }} position="br" children={<li id="PopupContainer" style={{ cursor: "pointer" }}><div style={{ height: '100%', position: 'relative', display: 'flex', alignItems: 'center' }}><Default />{querUserInfo.user.userLogoType == 2 ? (languages=='zh' ? <UserType style={{ width: 49, height: 12, position: 'absolute', left: -9, bottom: 10 }} /> : <CuserEn style={{ position: 'absolute', left:0, bottom: 10 }} />) : ""}</div><span style={{ color: isSpecialHeader ? "#fff" : "var(--Color-Text-Neutral-Default)" }}>{querUserInfo.user.username}</span></li>} />
      </>

    } else {
      return <li style={{ gap: 12 }}>
        <Link onClick={() => {
          navigate('/login')
        }} children={language('public651')} />
        <Link children={language('public67')} onClick={() => {
          window.open('https://www.tj-un.com/register.html')
        }} />
      </li>
    }
  }
  //消息中心
  function showMessageCenter() {
    const confirm = Modal({
      title: null,
      content: <MessageCenter close={() => {
        confirm.close()
      }} languages={languages}/>,
      style: { width: languages=='zh'?773:809, height: 540 },
      closable: false,
      footer: null,
      className: 'redqueenMessageDialog'
    })
  }
  return (
    <div className={cs(styles.headerBody, { specialHeader: isSpecialHeader, [styles.addBoxShadow]: isShowShadow })} style={{ 
      // minWidth: contentWidth == "100%" ? 1320 : contentWidth 
      minWidth: isApi? '' :1440,
      left: positionLeft
      }}>
      <div className={styles.leftMenuList}>

        {isSpecialHeader ? <HeaderLogos style={{ cursor: 'pointer' }} onClick={() => {
          navigate('/home')
        }} /> : <HeaderLogo style={{ cursor: 'pointer' }} onClick={() => {
          navigate('/home')
        }} />}
        {isApi?languages=='zh'?<><ApiLogo className={styles.apiLogo}/> <span className={styles.apiUseCenter}>使用中心</span></>:<><ApiLogo className={styles.apiLogo}/></>:<div className={styles.menuList} id="menuList">
          {
            showMenuData.map(item => {
              return <MenuList getPopupContainer={() => {
                return document.getElementById('menuList')
              }} menu={item.menu} key={item.type} type={item.type} onVisibleChange={(event) => {
                // console.log(123);
              }} children={item.children} to={item.to} />
            })
          }
        </div>}
      </div>
      <ul className={styles.rightUserInfo}>
        {showGlobalInput ? isApi?<><Button className={styles.downFile}  type='dark' onClick={()=>{  window.open(`/rq/doc${languages == 'en' ? '/en' : ''}/TianJiPartnersAPIUserManual.pdf`)    }}><Down/>{language('apiCenter2')}</Button><Button className={styles.downSdkFile} type='dark' onClick={()=>{   window.open(`/rq/doc${languages == 'en' ? '/en' : ''}/client.rar`)   }}><Down/>{language('apiCenter3')}</Button></>:<li style={{ position: 'relative' }}><HomeInputSearch className={styles.searchInputPosition} type="top" /></li> : ""}

        {/* <li><Button onClick={changeLanguageType} children="中英文切换" /></li> */}
        <li>
          <Link onClick={changeLanguageType}>
            <div className={cs(styles.changeLanguage, { [styles.spcetalLanguageStyle]: isSpecialHeader })}>
              {languages == 'en' ? (isSpecialHeader ? <ZhS /> : <Zh />) : (isSpecialHeader ? <EnS /> : <En />) }
              {languages == 'en' ? '中文' : 'English'}
            </div>
          </Link>
        </li>
        {isShowUserInfo()}
      </ul>
    </div>
  )
}
