import React from 'react'
import styles from './index.module.less'
import Search from '@/assets/empty/search.png'
// import Search from '@/assets/empty/search.svg'
// import Lock from '@/assets/empty/lock.png'
import Lock from '@/assets/empty/lock.svg'
import Notice from '@/assets/empty/notice.svg'
import NoLocked from '@/assets/empty/nolocked.png'

export default function Empty(props) {
    const { width = "100%", height = "100%", type = "search", message, other, iconStyle = {},gaps, messageStyle = {}} = props
    function emptyIcon() {
        // return <Search {...iconStyle}  style={{width:327, height:245,...iconStyle}}/>;
        switch (type) {
            case "search":
                // return <Search {...iconStyle}  style={{width:327, height:245,...iconStyle}}/>;
                return <img src={Search} style={{width:327, height:245,...iconStyle}}/>
            case 'lock':
                // return <img src={Lock} style={{width:327, height:245,...iconStyle}}/>
                return <Lock  style={{width:327, height:245,...iconStyle}}/>
            case 'notice':
            return <Notice  style={{...iconStyle}}/>
            case 'nolock':
                    return <img src={NoLocked} style={{width:327, height:245,...iconStyle}}/>
            default:
                // return <Search  {...iconStyle}/>;
        }
    }
    function emptyMessage() {
        if (message) {
            const _type = Object.prototype.toString.call(message);
            if (_type === "[object String]") {
                return <div style={{...messageStyle}}><p dangerouslySetInnerHTML={{ __html:message}}></p></div>
            } else if (_type === "[object Array]") {
                return (
                    <div style={{...messageStyle}}>
                        {message.map((item, index) => {
                            return <p key={index} dangerouslySetInnerHTML={{ __html:item}}></p>
                        })}
                    </div>
                )
            }
        } else {
            return ""
        }
    }
    function createOther() {
        if (other) {
            return (
                <div>
                    {other}
                </div>
            )
        } else {
            return "";
        }
    }
    return (
        <div className={styles.emptyBody} style={{ width, height, ...props.style }}>
            <div className={styles.mainContent} style={{marginTop:type == 'notice' ? '-74px' : 0, gap: type == 'notice' ? 0 : '20px'}}>
                {emptyIcon()}
                {emptyMessage()}
                {createOther()}
            </div>
        </div>
    )
}
